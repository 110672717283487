import request from '@/utils/request'
export function myNet(data) {
  return request({
    url: 'web/intimacy/myNetwork',
    method: 'get',
    data
  })
}
export function myNetRank(data) {
  return request({
    url: 'web/intimacy/networkRank',
    method: 'get',
    data
  })
}