<template>
    <div
      style="
        height: 100vh;
        background: #6B48DA;
        overflow-y: scroll;
      "
    >
    <div class="top_bg"  :style="{ backgroundImage: 'url(' + 'img/cpRank/info_bg.png' + ')' }">
      <div class="back_btn" style="background-image: url('img/cpRank/back_btn.png')" @click="goBack"></div>
    </div>
 
      <!-- <div v-if="nodata" class="noData">
          <img :src="'img/noData.png'" alt="" />
        </div> -->
        <div class="list_box" @scroll="handleScroll">
            <div v-for="(item,index) in list" :key="index" class="rank_item" style="background-image: url('img/cpRank/rank_bg.png');">
              <div class="sub_bg">{{'第'+(index+1)+'期'}}</div>
              <div class="bottom_bg" style="background-image: url('img/cpRank/value_bg.png');">{{item.intimacy_value}}</div>
                <div class="left_bg" style="background-image: url('img/cpRank/left_bg.png');">
                  <img class="face_left" :src="item.a_head_portrait" alt="">
                  <div class="a_nickname">{{item.a_nickname}}</div>
                </div>
                <div class="aixin" style="background-image: url('img/cpRank/aixin.png');"></div>
                <div class="right_bg" style="background-image: url('img/cpRank/right_bg.png');">
                  <img class="face_right" :src="item.b_head_portrait" alt="">
                  <div class="b_nickname">{{item.b_nickname}}</div>
                </div>
            </div>
        </div>
 
    </div>
  </template>
    
    <script>
  import "@/assets/css/cpRank.css";
  import { myNetRank } from "@/api/relationNet";
  export default {
    name: "room",
    data() {
      return {
        loading: false,
        nodata: false,
        list:[],
        page:1
      };
    },
    created() {
      this.getData();
    },
    methods: {
      handleScroll(e) {
        const container = e.target;
        if (
          container.scrollHeight - container.scrollTop <=
          container.clientHeight
        ) {
          this.page = this.page + 1;
          this.getData();
        }
      },
      getData() {
        let Params = {
          page: this.page,
          rows: 15,
          type:4
        };
        this.loading = true;
        myNetRank(Params)
          .then(
            (response) => {
              this.loading = false;
              if (response.data.length == 0) {
                this.nodata = true;
              }
              this.list = this.list.concat(response.data);
            },
            (error) => {
              this.$toast(error);
            }
          )
          .catch((error) => {
            this.$toast(error);
          });
      },
      closePage() {
        let params = "调用";
        this.$bridge.callhandler("closePage", params, (data) => {
          // 处理返回数据
        });
      },
      goBack(){
        this.$router.go(-1)
      }
    },
  };
  </script>
    
    <!-- Add "scoped" attribute to limit CSS to this component only -->